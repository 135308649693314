import React, { useEffect, useState } from 'react';
import { supabase } from '../supabase/supabase';
import Layout from '../layout/Layout';
import { useLogin } from '../context/LoginContext';
import { useNavigate } from 'react-router-dom';

const Form = () => {
    const [loading, setLoading] = useState(true);
    const [sectores, setSectores] = useState([]);
    const [selectedSector, setSelectedSector] = useState('');
    const [dataPeriodicidad, setDataPeriodicidad] = useState('');
    const [periodicidad, setPeriodicidad] = useState([]);
    const [selectedIndicador, setSelectedIndicador] = useState('');
    const [selectedOpciones, setSelectedOpciones] = useState('');
    const [indicadores, setIndicadores] = useState([]);
    const [opcionesIndicador, setOpcionesIndicador] = useState([]);
    const { getSessionAuth } = useLogin();
    const [userData, setUserData] = useState(null);
    const navigate = useNavigate();
    const [year, setYear] = useState('');
    const [measurementUnits, setMeasurementUnits] = useState('');
    const [tipoGrafico, setTipoGrafico] = useState('');
    const [guardadoExitoso, setGuardadoExitoso] = useState(false);
    const [mostrarBotonVerTablas, setMostrarBotonVerTablas] = useState(false);


    const handleYearChange = (e) => {
        setYear(e.target.value);
    };

    const handleMeasurementUnitsChange = (e) => {
        setMeasurementUnits(e.target.value);
        console.log(e.target.value);
    };

    useEffect(() => {
        const sessionAuth = async () => {
            const session = await getSessionAuth();
            if (session) {
                await getUser(session.id);
                setLoading(false);
            } else {
                navigate("/", { replace: true });
            }
        };
        sessionAuth();
    }, []);

    const getUser = async (id) => {
        const { error, data } = await supabase
            .from("users")
            .select()
            .eq(`id`, id)
            .single();
        if (error) throw error.message;
        setUserData(data);
    };

    useEffect(() => {
        const fetchSectores = async () => {
            try {
                const { data, error } = await supabase.from('sectores').select('*');
                if (error) {
                    throw error;
                }
                setSectores(data);
            } catch (error) {
                console.error('Error al obtener los sectores:', error.message);
            }
        };

        fetchSectores();
    }, []);

    useEffect(() => {
        const fetchPeriodicidad = async () => {
            try {
                const { data, error } = await supabase.from('periodicidad').select('*');
                if (error) {
                    throw error;
                }
                setPeriodicidad(data);
            } catch (error) {
                console.error('Error al obtener los sectores:', error.message);
            }
        };

        fetchPeriodicidad();
    }, []);

    const handleSectorChange = async (selectedValue) => {
        const selectedSectorId = sectores.find((sector) => sector.name === selectedValue)?.id;

        if (selectedSectorId) {
            setSelectedSector(selectedValue);

            try {
                const { data, error } = await supabase
                    .from('rel_sectores_indicadores')
                    .select(`*,indicadores(name)`)
                    .eq('id_sectores', selectedSectorId);

                if (error) {
                    throw error;
                }

                const indicadoresData = data.map((rel) => rel.indicadores.name);
                setIndicadores(indicadoresData);
            } catch (error) {
                console.error('Error al obtener los indicadores:', error.message);
            }
        }
    };

    const handleIndicadorChange = async (selectValue) => {
        try {
            const { data: Indicador, error } = await supabase
                .from('indicadores')
                .select('id')
                .eq('name', selectValue);

            if (error) {
                throw error;
            }

            if (Indicador.length > 0) {
                const indicadorId = Indicador[0].id;
                setSelectedIndicador(indicadorId);

                try {
                    const { data: opcionesInd, error } = await supabase
                        .from('rel_indicadores_indicadores_opciones3')
                        .select(`*,indicadores_opciones3(name)`)
                        .eq('id_indicadores', indicadorId);

                    if (error) {
                        throw error;
                    }

                    const opcionesData = opcionesInd.map((rel) => rel.indicadores_opciones3.name);
                    setOpcionesIndicador(opcionesData);

                } catch (error) {
                    console.error('Error al obtener las opciones de indicadores:', error.message);
                }
            }
        } catch (error) {
            console.error('Error al obtener los indicadores:', error.message);
        }
    };

    const handleOpcionesChange = async (selectValue) => {
        console.log("Opciones", selectValue);
        try {
            const { data: Opciones, error } = await supabase
                .from('indicadores_opciones3')
                .select('id')
                .eq('name', selectValue);

            console.log("DAta", Opciones);
            const opcionesId = Opciones[0].id;
            setSelectedOpciones(opcionesId);

            if (error) {
                throw error;
            }

        } catch (error) {
            console.error('Error al obtener las opciones:', error.message);
        }
    };

    const handlePeriodicidadChange = async (selectedValue) => {
        console.log(selectedValue);
        const selectedPeriodicidad = periodicidad.find((periodicidad) => periodicidad.name === selectedValue)?.id;

        if (selectedPeriodicidad) {
            setDataPeriodicidad(selectedPeriodicidad);
        };
    };

    const [datos, setDatos] = useState({
        enero: '',
        febrero: '',
        marzo: '',
        abril: '',
        mayo: '',
        junio: '',
        julio: '',
        agosto: '',
        setiembre: '',
        octubre: '',
        noviembre: '',
        diciembre: '',
    });

    const guardarDatos = async () => {
        const jsonData = JSON.stringify(datos);

        try {
            const { data: sectorData, error: sectorError } = await supabase
                .from('sectores')
                .select('id')
                .eq('name', selectedSector);

            if (sectorError) {
                console.error('Error al obtener el ID del sector:', sectorError.message);
                return;
            }

            const sectorId = sectorData[0]?.id;

            try {
                const { data, error } = await supabase
                    .from('general')
                    .upsert([
                        {
                            meses: jsonData,
                            id_sectores: sectorId,
                            id_indicadores: selectedIndicador,
                            id_opciones_indicadores3: selectedOpciones,
                            id_periodicidad: dataPeriodicidad,
                            year: year,
                            measurement_units: measurementUnits,
                            tipo_grafico: tipoGrafico,
                        },
                    ]);

                if (error) {
                    console.error('Error al enviar datos a Supabase:', error);
                } else {
                    console.log('Datos enviados exitosamente:', data);
                    setGuardadoExitoso(true);
                    setMostrarBotonVerTablas(true);
                }
            } catch (error) {
                console.error('Error general:', error.message);
            }
        } catch (error) {
            console.error('Error al obtener el ID del sector:', error.message);
        }
    };


    const handleInputChange = (mes, valor) => {
        setDatos((prevDatos) => ({
            ...prevDatos,
            [mes]: valor,
        }));
    };


    return (
        <Layout>
            <h1 className='font-bold text-4xl m-4 text-[#058237]'>FORMULARIO</h1>
            <form className="max-w-full m-40 mt-20">
                <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-state">
                            Sectores
                        </label>
                        <div className="relative">
                            <select
                                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id="grid-state"
                                onChange={(e) => handleSectorChange(e.target.value)}
                            >
                                <option value="">Selecciona una opción</option>
                                {sectores.map((sector) => (
                                    <option key={sector.name} value={sector.name}>
                                        {sector.name}
                                    </option>
                                ))}
                            </select>
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                </svg>
                            </div>
                        </div>
                    </div>

                    <div className="w-full md:w-1/2 px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-state">
                            Indicadores
                        </label>
                        <div className="relative">
                            <select
                                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id="grid-state"
                                onChange={(e) => handleIndicadorChange(e.target.value)}
                            >
                                <option value="">Selecciona una opción</option>
                                {indicadores.map((indicador) => (
                                    <option key={indicador} value={indicador}>
                                        {indicador}
                                    </option>
                                ))}
                            </select>
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                </svg>
                            </div>
                        </div>
                    </div>

                    <div className="w-full px-3 mt-10">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-state">
                            Opciones de indicadores
                        </label>
                        <div className="relative">
                            <select
                                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id="grid-state"
                                onChange={(e) => handleOpcionesChange(e.target.value)}
                            >
                                <option value="">Selecciona una opción</option>
                                <option>Ninguno</option>
                                {opcionesIndicador.map((opciones) => (
                                    <option key={opciones} value={opciones}>
                                        {opciones}
                                    </option>
                                ))}
                            </select>
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                </svg>
                            </div>
                        </div>
                    </div>

                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0 mt-8">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-state">
                            Periodicidad
                        </label>
                        <div className="relative">
                            <select
                                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id="grid-state"
                                onChange={(e) => handlePeriodicidadChange(e.target.value)}
                            >
                                <option value="">Selecciona una opción</option>
                                {periodicidad.map((period) => (
                                    <option key={period.name} value={period.name}>
                                        {period.name}
                                    </option>
                                ))}
                            </select>
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                </svg>
                            </div>
                        </div>
                    </div>

                    <div className="w-full md:w-1/2 px-3 mt-8">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-state">
                            Año
                        </label>
                        <div className="relative">
                            <input
                                className='leading-1 py-3 w-full bg-[#E5E7EB] pl-2 rounded-s-md'
                                onChange={handleYearChange}
                                value={year}
                                placeholder="Ingrese el año"
                            />
                        </div>
                    </div>
                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0 mt-8">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-state">
                            Unidades de medida
                        </label>
                        <div className="relative">
                            <input
                                className='leading-1 py-3 w-full bg-[#E5E7EB] pl-2 rounded-s-md'
                                onChange={handleMeasurementUnitsChange}
                                value={measurementUnits}
                                placeholder="Ingrese las unidades de medida"
                            />
                        </div>
                    </div>
                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0 mt-8">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="tipoGrafico">
                            Tipo de gráfico
                        </label>
                        <div className="relative">
                            <select
                                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id="tipoGrafico"
                                onChange={(e) => setTipoGrafico(e.target.value)}
                            >
                                <option value="">Selecciona una opción</option>
                                <option value="Lineal">Lineal</option>
                                <option value="Circular">Circular</option>
                                <option value="Barras">Barras</option>
                            </select>
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div className='m-10'>
                        <h1 className='font-bold text-lg'>MESES:</h1>
                    </div>
                    <div className='flex grid-cols-2 justify-evenly'>
                        <div className=''>
                            <div className="my-2">
                                <div className='flex items-center gap-4 justify-between'>
                                    <label className="uppercase tracking-wide text-green-700 text-sm font-bold" htmlFor="grid-state">
                                        Enero
                                    </label>
                                    <div className="">
                                        <input
                                            className='border-2 rounded-xl border-black p-1 pl-2'
                                            onChange={(e) => handleInputChange('enero', e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="my-2">
                                <div className='flex items-center gap-4 justify-between'>
                                    <label className="uppercase tracking-wide text-green-700 text-sm font-bold" htmlFor="grid-state">
                                        Febrero
                                    </label>
                                    <div className="">
                                        <input
                                            className='border-2 rounded-xl border-black p-1 pl-2'
                                            onChange={(e) => handleInputChange('febrero', e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="my-2">
                                <div className='flex items-center gap-4 justify-between'>
                                    <label className="uppercase tracking-wide text-green-700 text-sm font-bold" htmlFor="grid-state">
                                        Marzo
                                    </label>
                                    <div className="">
                                        <input
                                            className='border-2 rounded-xl border-black p-1 pl-2'
                                            onChange={(e) => handleInputChange('marzo', e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="my-2">
                                <div className='flex items-center gap-4 justify-between'>
                                    <label className="uppercase tracking-wide text-green-700 text-sm font-bold" htmlFor="grid-state">
                                        Abril
                                    </label>
                                    <div className="">
                                        <input
                                            className='border-2 rounded-xl border-black p-1 pl-2'
                                            onChange={(e) => handleInputChange('abril', e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="my-2">
                                <div className='flex items-center gap-4 justify-between'>
                                    <label className="uppercase tracking-wide text-green-700 text-sm font-bold" htmlFor="grid-state">
                                        Mayo
                                    </label>
                                    <div className="">
                                        <input
                                            className='border-2 rounded-xl border-black p-1 pl-2'
                                            onChange={(e) => handleInputChange('mayo', e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="my-2">
                                <div className='flex items-center gap-4 justify-between'>
                                    <label className="uppercase tracking-wide text-green-700 text-sm font-bold" htmlFor="grid-state">
                                        Junio
                                    </label>
                                    <div className="">
                                        <input
                                            className='border-2 rounded-xl border-black p-1 pl-2'
                                            onChange={(e) => handleInputChange('junio', e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=''>
                            <div className="my-2">
                                <div className='flex items-center gap-4 justify-between'>
                                    <label className="uppercase tracking-wide text-green-700 text-sm font-bold" htmlFor="grid-state">
                                        Julio
                                    </label>
                                    <div className="">
                                        <input
                                            className='border-2 rounded-xl border-black p-1 pl-2'
                                            onChange={(e) => handleInputChange('julio', e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="my-2">
                                <div className='flex items-center gap-4 justify-between'>
                                    <label className="uppercase tracking-wide text-green-700 text-sm font-bold" htmlFor="grid-state">
                                        Agosto
                                    </label>
                                    <div className="">
                                        <input
                                            className='border-2 rounded-xl border-black p-1 pl-2'
                                            onChange={(e) => handleInputChange('agosto', e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="my-2">
                                <div className='flex items-center gap-4 justify-between'>
                                    <label className="uppercase tracking-wide text-green-700 text-sm font-bold" htmlFor="grid-state">
                                        Setiembre
                                    </label>
                                    <div className="">
                                        <input
                                            className='border-2 rounded-xl border-black p-1 pl-2'
                                            onChange={(e) => handleInputChange('setiembre', e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="my-2">
                                <div className='flex items-center gap-4 justify-between'>
                                    <label className="uppercase tracking-wide text-green-700 text-sm font-bold" htmlFor="grid-state">
                                        Octubre
                                    </label>
                                    <div className="">
                                        <input
                                            className='border-2 rounded-xl border-black p-1 pl-2'
                                            onChange={(e) => handleInputChange('octubre', e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="my-2">
                                <div className='flex items-center gap-4 justify-between'>
                                    <label className="uppercase tracking-wide text-green-700 text-sm font-bold" htmlFor="grid-state">
                                        Noviembre
                                    </label>
                                    <div className="">
                                        <input
                                            className='border-2 rounded-xl border-black p-1 pl-2'
                                            onChange={(e) => handleInputChange('noviembre', e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="my-2">
                                <div className='flex items-center gap-4 justify-between'>
                                    <label className="uppercase tracking-wide text-green-700 text-sm font-bold" htmlFor="grid-state">
                                        Diciembre
                                    </label>
                                    <div className="">
                                        <input
                                            className='border-2 rounded-xl border-black p-1 pl-2'
                                            onChange={(e) => handleInputChange('diciembre', e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                {guardadoExitoso && (
                    <div>
                        <p className="text-green-600 font-semibold mt-4">La información se ha guardado correctamente</p>
                        {mostrarBotonVerTablas && (
                            <button
                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-xl mt-2"
                                onClick={() => navigate("/tablas")}
                            >
                                Ver Tablas
                            </button>
                        )}
                    </div>
                )}

                <button
                    type="button"
                    className="bg-green-700 hover:bg-green-500 text-white font-bold py-2 px-4 rounded-xl mt-10"
                    onClick={guardarDatos}
                >
                    Guardar
                </button>
            </form>
        </Layout>
    );
};

export default Form;