import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import Layout from '../layout/Layout';
import { useLogin } from '../context/LoginContext';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../supabase/supabase';
import ExcelJS from 'exceljs';

const Tablas = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]); 
  const { getSessionAuth } = useLogin();
  const navigate = useNavigate();
  const [showNoData, setShowNoData] = useState(false);

  const columns = [
    {
      field: 'id_sectores',
      name: 'Sectores',
      width: '150px',
      sortable: true,
      wrap: 'yes',
      selector: row => row.sectorName 
    },
    {
      field: 'id_indicadores',
      name: 'Indicadores',
      width: '200px',
      wrap: 'yes',
      sortable: true,
      selector: row => row.indicadorName 
    },
    {
      field: 'id_opciones_indicadores3',
      name: 'Opciones',
      width: '200px',
      wrap: 'yes',
      sortable: true,
      selector: row => row.opcionName 
    },
    {
      field: 'meses',
      name: 'Meses',
      width: '200px',
      wrap: 'yes',
      selector: row => row.meses
    },
    {
      field: 'year',
      name: 'Año',
      width: '80px',
      wrap: 'yes',
      selector: row => row.year
    },
    {
      field: 'title_graphic',
      name: 'Título',
      width: '80px',
      wrap: 'yes',
      selector: row => row.title_graphic
    },
    {
      field: 'nombre_eje',
      name: 'Eje',
      width: '80px',
      wrap: 'yes',
      selector: row => row.nombre_eje
    },
    {
      field: 'font',
      name: 'Fuente',
      width: '80px',
      wrap: 'yes',
      selector: row => row.font
    },
    {
      cell: row => <button className="btn-editar" onClick={() => handleEdit(row.id)}>Editar</button>,
      // ignoreRowClick: true,
      // allowoverflow: true,
      // button: true,
      width: '100px',
    },
    {
      cell: row => <button className="btn-borrar" onClick={() => handleDelete(row.id)}>Borrar</button>,
      // ignoreRowClick: true,
      // allowoverflow: true,
      // button: true,
      width: '100px'
    }
  ];

  useEffect(() => {
    const sessionAuth = async () => {
      const session = await getSessionAuth();
      if (session) {
        setLoading(false);
      } else {
        navigate("/", { replace: true });
      }
    };
    sessionAuth();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: generalData, error: generalError } = await supabase
          .from("general").select("*");
        if (generalError) {
          console.error("Error al obtener la data: ", generalError);
          return;
        }

        const processedData = await Promise.all(generalData.map(async (row) => {
          const { data: sectorData, error: sectorError } = await supabase
            .from("sectores")
            .select("name")
            .eq("id", row.id_sectores)
            .single();
          const { data: indicadorData, error: indicadorError } = await supabase
            .from("indicadores")
            .select("name")
            .eq("id", row.id_indicadores)
            .single();
          const { data: opcionData, error: opcionError } = await supabase
            .from("indicadores_opciones3")
            .select("name")
            .eq("id", row.id_opciones_indicadores3)
            .single();
            
          return {
            ...row,
            sectorName: sectorData ? sectorData.name : "N/A",
            indicadorName: indicadorData ? indicadorData.name : "N/A",
            opcionName: opcionData ? opcionData.name : "N/A"
          };
        }));
        setData(processedData);
        setOriginalData(processedData);
      } catch (error) {
        console.error("Error al obtener la data", error.message);
      }
    };

    fetchData();
  }, []);

  function handleFilter(event) {
    const value = event.target.value.toLowerCase();
    if (value === '') {
      setData(originalData);
    } else {
      const newData = originalData.filter(row => row.sectorName.toLowerCase().includes(value));
      setData(newData);
    }
  }

  function handleExportToExcel() {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');
  
    // Agregar columnas al worksheet
    worksheet.columns = [
      { header: 'Sectores', key: 'sectorName', width: 15 },
      { header: 'Indicadores', key: 'indicadorName', width: 20 },
      { header: 'Opciones', key: 'opcionName', width: 20 },
      { header: 'Meses', key: 'meses', width: 15 },
      { header: 'Año', key: 'year', width: 10 }
    ];
  
    // Agregar filas de datos al worksheet
    data.forEach(row => {
      worksheet.addRow({
        sectorName: row.sectorName,
        indicadorName: row.indicadorName,
        opcionName: row.opcionName,
        meses: row.meses,
        year: row.year
      });
    });
  
    // Escribir el workbook a un archivo
    workbook.xlsx.writeBuffer().then(buffer => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'data.xlsx';
      a.click();
    });
  }

  const handleEdit = (id) => {
    navigate(`/editar/${id}`);
  };

  const handleDelete = async (id) => {
    console.log("El id es:", id);
    const confirmDelete = window.confirm("¿Estás seguro de que deseas eliminar este elemento?");
    if (confirmDelete) {
      try {
        await supabase.from('general').delete().eq('id', id);
        window.location.reload();
      } catch (error) {
        console.error("Error al eliminar el elemento:", error.message);
      }
    }
  };

  const NoDataComponent = () => (
    <div className="text-center">
      {showNoData ? (
        "No hay datos disponibles"
      ) : (
        "Cargando información..."
      )}
    </div>
  );

  return (
    <Layout>
      <div className='flex justify-between items-center pb-8'>
        <div>
          <label htmlFor="searchInput" className="input-label font-medium text-[#058237]">Buscar: </label>
          <input type="text" className='border-black border-2 rounded-lg border-2 ' onChange={handleFilter}/>
        </div>
        <button className="bg-[#058237] text-white text-sm text-center p-2 rounded-lg hover:text-white hover:bg-[#36915a] transition-colors duration-300" onClick={handleExportToExcel}>Exportar a Excel</button>
      </div>
      <DataTable
        columns={columns}
        data={data}
        fixedHeader
        pagination
        noDataComponent={<NoDataComponent />} 
      />
    </Layout>
  )
}

export default Tablas;
