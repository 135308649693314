import React, { useEffect, useState } from 'react';
import { supabase } from '../supabase/supabase';
import Layout from '../layout/Layout';
import { useLogin } from '../context/LoginContext';
import { useNavigate, useParams } from 'react-router-dom';

const Edit = () => {
    const [loading, setLoading] = useState(true);
    const [sectores, setSectores] = useState([]);
    const [selectedSector, setSelectedSector] = useState('');
    const [selectIndicador, setSelectIndicador] = useState('');
    const [dataYear, setDataYear] = useState('');
    const [dataPeriodicidad, setDataPeriodicidad] = useState('');
    const [periodicidad, setPeriodicidad] = useState([]);
    const [selectedIndicador, setSelectedIndicador] = useState('');
    const [selectedOpciones, setSelectedOpciones] = useState('');
    const [indicadores, setIndicadores] = useState([]);
    const [opcionesIndicador, setOpcionesIndicador] = useState([]);
    const { getSessionAuth } = useLogin();
    const [userData, setUserData] = useState(null);
    const navigate = useNavigate();
    const [year, setYear] = useState('');
    const [title, setTitle] = useState('');
    const [eje, setEje] = useState('');
    const [font, setFont] = useState('');
    const [measurementUnits, setMeasurementUnits] = useState('');
    const [tipoGrafico, setTipoGrafico] = useState('');
    const [guardadoExitoso, setGuardadoExitoso] = useState(false);
    const [mostrarBotonVerTablas, setMostrarBotonVerTablas] = useState(false);
    const { id } = useParams();


    const [idSector, setIdSector] = useState('');
    const [idIndicador, setIdIndicador] = useState('');
    const [idOpciones, setIdOpciones] = useState('');
    const [idPeriodicidad, setIdPeriodicidad] = useState('');



    const [datos, setDatos] = useState({
        enero: '',
        febrero: '',
        marzo: '',
        abril: '',
        mayo: '',
        junio: '',
        julio: '',
        agosto: '',
        setiembre: '',
        octubre: '',
        noviembre: '',
        diciembre: '',
    });

    useEffect(() => {
        const sessionAuth = async () => {
            const session = await getSessionAuth();
            if (session) {
                await getUser(session.id);
                setLoading(false);
            } else {
                navigate("/", { replace: true });
            }
        };
        sessionAuth();
    }, []);

    const getUser = async (id) => {
        const { error, data } = await supabase
            .from("users")
            .select()
            .eq(`id`, id)
            .single();
        if (error) throw error.message;
        setUserData(data);
    };

    useEffect(() => {
        const fetchSectores = async () => {
            try {
                const { data, error } = await supabase.from('sectores').select('*');
                if (error) {
                    throw error;
                }
                setSectores(data);
            } catch (error) {
                console.error('Error al obtener los sectores:', error.message);
            }
        };

        fetchSectores();
    }, []);

    useEffect(() => {
        const fetchPeriodicidad = async () => {
            try {
                const { data, error } = await supabase.from('periodicidad').select('*');
                if (error) {
                    throw error;
                }
                setPeriodicidad(data);
            } catch (error) {
                console.error('Error al obtener los sectores:', error.message);
            }
        };

        fetchPeriodicidad();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data, error } = await supabase
                    .from('general')
                    .select('*')
                    .eq('id', id)
                    .single();

                if (error) {
                    throw error;
                }

                setYear(data.year);
                console.log(data.year);
                setMeasurementUnits(data.measurement_units);
                setTipoGrafico(data.tipo_grafico);
                setDatos(JSON.parse(data.meses));
                setSelectedSector(data.id_sectores);
                setSelectedIndicador(data.id_indicadores);
                setSelectedOpciones(data.id_opciones_indicadores3);
                setDataPeriodicidad(data.id_periodicidad);

                setIdSector(data.id_sectores);
                setIdIndicador(data.id_indicadores);
                setIdOpciones(data.id_opciones_indicadores3);
                setIdPeriodicidad(data.id_periodicidad)

                // Fetch the sector name and indicator name to display in the selects
                const sectorResponse = await supabase.from('sectores').select('name').eq('id', data.id_sectores).single();
                if (sectorResponse.error) throw sectorResponse.error;
                setSelectedSector(sectorResponse.data.name);

                const indicadorResponse = await supabase.from('indicadores').select('name').eq('id', data.id_indicadores).single();
                if (indicadorResponse.error) throw indicadorResponse.error;
                setSelectedIndicador(indicadorResponse.data.name);

                const opcionesResponse = await supabase.from('indicadores_opciones3').select('name').eq('id', data.id_opciones_indicadores3).single();
                if (opcionesResponse.error) throw opcionesResponse.error;
                setSelectedOpciones(opcionesResponse.data.name);

                const periodicidadResponse = await supabase.from('periodicidad').select('name').eq('id', data.id_periodicidad).single();
                if (periodicidadResponse.error) throw periodicidadResponse.error;
                setDataPeriodicidad(periodicidadResponse.data.name);

                const yearResponse = await supabase.from('general').select('year').eq('id', data.year).single();
                if (yearResponse.error) throw yearResponse.error;
                setYear(yearResponse.data.name);

                setLoading(false);

            } catch (error) {
                console.error('Error al cargar los datos:', error.message);
            }
        };

        fetchData();
    }, [id]);

    console.log("afuera", selectIndicador);

    const handleSectorChange = async (selectedValue) => {
        const selectedSectorId = sectores.find((sector) => sector.name === selectedValue)?.id;

        if (selectedSectorId) {
            setSelectedSector(selectedValue);

            try {
                const { data, error } = await supabase
                    .from('rel_sectores_indicadores')
                    .select(`*,indicadores(name)`)
                    .eq('id_sectores', selectedSectorId);

                if (error) {
                    throw error;
                }

                const indicadoresData = data.map((rel) => rel.indicadores.name);
                setIndicadores(indicadoresData);
            } catch (error) {
                console.error('Error al obtener los indicadores:', error.message);
            }
        }
    };

    const handleIndicadorChange = async (selectValue) => {

        try {
            const { data: Indicador, error } = await supabase
                .from('indicadores')
                .select('id')
                .eq('name', selectValue);

            if (error) {
                throw error;
            }

            if (Indicador.length > 0) {
                const indicadorId = Indicador[0].id;
                setSelectedIndicador(indicadorId);

                try {
                    const { data: opcionesInd, error } = await supabase
                        .from('rel_indicadores_indicadores_opciones3')
                        .select(`*,indicadores_opciones3(name)`)
                        .eq('id_indicadores', indicadorId);

                    if (error) {
                        throw error;
                    }

                    const opcionesData = opcionesInd.map((rel) => rel.indicadores_opciones3.name);
                    setOpcionesIndicador(opcionesData);

                } catch (error) {
                    console.error('Error al obtener las opciones de indicadores:', error.message);
                }
            }
        } catch (error) {
            console.error('Error al obtener los indicadores:', error.message);
        }
    };

    const handleOpcionesChange = async (selectValue) => {
        console.log("Opciones", selectValue);
        try {
            const { data: Opciones, error } = await supabase
                .from('indicadores_opciones3')
                .select('id')
                .eq('name', selectValue);

            console.log("DAta", Opciones);
            const opcionesId = Opciones[0].id;
            setSelectedOpciones(opcionesId);

            if (error) {
                throw error;
            }

        } catch (error) {
            console.error('Error al obtener las opciones:', error.message);
        }
    };

    const handlePeriodicidadChange = async (selectedValue) => {
        console.log(selectedValue);
        const selectedPeriodicidad = periodicidad.find((periodicidad) => periodicidad.name === selectedValue)?.id;

        if (selectedPeriodicidad) {
            setDataPeriodicidad(selectedPeriodicidad);
        };
    };

    const guardarDatos = async () => {
        const jsonData = JSON.stringify(datos);

        try {
            const { data: sectorData, error: sectorError } = await supabase
                .from('sectores')
                .select('id')
                .eq('name', selectedSector);

            if (sectorError) {
                console.error('Error al obtener el ID del sector:', sectorError.message);
                return;
            }

            const sectorId = sectorData[0]?.id;

            try {
                const { data, error } = await supabase
                    .from('general')
                    .upsert([
                        {
                            id: id,
                            meses: jsonData,
                            id_sectores: sectorId,
                            id_indicadores: idIndicador,
                            id_opciones_indicadores3: idOpciones,
                            id_periodicidad: idPeriodicidad,
                            year: year,
                            measurement_units: measurementUnits,
                            tipo_grafico: tipoGrafico,
                            title_graphic: title,
                            nombre_eje: eje,
                            font: font
                        },
                    ])
                    .eq('id', id);

                if (error) {
                    throw error;
                }
                setGuardadoExitoso(true);
                setMostrarBotonVerTablas(true);
            } catch (error) {
                console.error('Error al guardar los datos:', error.message);
            }
        } catch (error) {
            console.error('Error al guardar los datos:', error.message);
        }
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setDatos((prevDatos) => ({
            ...prevDatos,
            [name]: value,
        }));
    };

    const handleYearChange = (event) => {
        setYear(event.target.value);
    };

    const handleTitleChange = (event) => {
        setTitle(event.target.value);
    };

    const handleEjeChange = (event) => {
        setEje(event.target.value);
    };

    const handleFontChange = (event) => {
        setFont(event.target.value);
    };

    const handleMeasurementUnitsChange = (event) => {
        setMeasurementUnits(event.target.value);
    };

    const handleTipoGraficoChange = (event) => {
        setTipoGrafico(event.target.value);
    };

    return (
        <Layout>
            <h1 className='font-bold text-4xl m-4 text-[#058237]'>Editar Formulario</h1>
            {guardadoExitoso && <p>Datos guardados con éxito</p>}
            <form className="max-w-full m-[40px] mt-20">
                <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full md:w-1/3 px-3 md:mb-0 mb-14">
                        <label className="block uppercase tracking-wide text-green-700 text-sm font-bold mb-2" htmlFor="sector">
                            Sector:
                        </label>
                        <div className="relative">
                            <h1>{selectedSector}</h1>
                        </div>
                    </div>
                    <div className="w-full md:w-1/3 px-3 mb-14">
                        <label className="block uppercase tracking-wide text-green-700 text-sm font-bold mb-2" htmlFor="indicador">Indicador:</label>
                        <div className="relative">
                            <h1>{selectedIndicador}</h1>
                        </div>
                    </div>

                    <div className="w-full md:w-1/3 px-3 mb-14">
                        <label className="block uppercase tracking-wide text-green-700 text-sm font-bold mb-2" htmlFor="opciones">
                            Opciones:
                        </label>
                        <div className="relative">
                            <h1>{selectedOpciones}</h1>
                        </div>
                    </div>
                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0 mt-8">
                        <label className="block uppercase tracking-wide text-green-700 text-sm font-bold mb-2" htmlFor="periodicidad">
                            Periodicidad:
                        </label>
                        <div className="relative">
                            <select
                                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id="periodicidad"
                                name="periodicidad"
                                value={dataPeriodicidad}
                                onChange={(e) => handlePeriodicidadChange(e.target.value)}
                            >
                                <option value="">Seleccione una periodicidad</option>
                                {periodicidad.map((per) => (
                                    <option key={per.id} value={per.name}>
                                        {per.name}
                                    </option>
                                ))}
                            </select>
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div className="w-full md:w-1/2 px-3 mt-8">
                        <label className="block uppercase tracking-wide text-green-700 text-sm font-bold mb-2" htmlFor="year">
                            Año:
                        </label>
                        <div className="relative">
                            <input
                                className='leading-1 py-3 w-full bg-[#E5E7EB] pl-2 rounded-s-md'
                                type="text"
                                id="year"
                                name="year"
                                value={year}
                                onChange={handleYearChange}
                                placeholder="Ingrese el año"
                            />
                        </div>
                    </div>
                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0 mt-8">
                        <label className="block uppercase tracking-wide text-green-700 text-sm font-bold mb-2" htmlFor="measurement_units">
                            Unidades de Medida:
                        </label>
                        <div className="relative">
                            <input
                                className='leading-1 py-3 w-full bg-[#E5E7EB] pl-2 rounded-s-md'
                                type="text"
                                id="measurement_units"
                                name="measurement_units"
                                value={measurementUnits}
                                onChange={handleMeasurementUnitsChange}
                                placeholder="Ingrese las unidades de medida"
                            />
                        </div>
                    </div>
                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0 mt-8">
                        <label className="block uppercase tracking-wide text-green-700 text-sm font-bold mb-2" htmlFor="tipo_grafico">
                            Tipo de Gráfico:
                        </label>
                        <div className="relative">
                            <select
                                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                type="text"
                                id="tipo_grafico"
                                name="tipo_grafico"
                                value={tipoGrafico}
                                onChange={handleTipoGraficoChange}
                            >
                                <option value="">Selecciona una opción</option>
                                <option value="Lineal">Lineal</option>
                                <option value="Circular">Circular</option>
                                <option value="Barras">Barras</option>
                            </select>
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div className="w-full md:w-1/2 px-3 mt-8">
                        <label className="block uppercase tracking-wide text-green-700 text-sm font-bold mb-2" htmlFor="year">
                            Título:
                        </label>
                        <div className="relative">
                            <input
                                className='leading-1 py-3 w-full bg-[#E5E7EB] pl-2 rounded-s-md'
                                type="text"
                                id="title"
                                name="title"
                                value={title}
                                onChange={handleTitleChange}
                                placeholder="Ingrese el título"
                            />
                        </div>
                    </div>
                    <div className="w-full md:w-1/2 px-3 mt-8">
                        <label className="block uppercase tracking-wide text-green-700 text-sm font-bold mb-2" htmlFor="year">
                            Nombre de Eje:
                        </label>
                        <div className="relative">
                            <input
                                className='leading-1 py-3 w-full bg-[#E5E7EB] pl-2 rounded-s-md'
                                type="text"
                                id="eje"
                                name="eje"
                                value={eje}
                                onChange={handleEjeChange}
                                placeholder="Ingrese el título del eje"
                            />
                        </div>
                    </div>
                    <div className="w-full md:w-1/2 px-3 mt-8">
                        <label className="block uppercase tracking-wide text-green-700 text-sm font-bold mb-2" htmlFor="year">
                            Fuente:
                        </label>
                        <div className="relative">
                            <input
                                className='leading-1 py-3 w-full bg-[#E5E7EB] pl-2 rounded-s-md'
                                type="text"
                                id="font"
                                name="font"
                                value={font}
                                onChange={handleFontChange}
                                placeholder="Ingrese el título del eje"
                            />
                        </div>
                    </div>
                </div>

                <div>
                    <div className='m-10'>
                        <h1 className='font-bold text-lg'>MESES:</h1>
                    </div>
                    <div className='flex grid-cols-2 justify-evenly'>
                        <div className=''>
                            <div className="my-2">
                                <div className='flex items-center gap-4 justify-between'>
                                    <label className="uppercase tracking-wide text-green-700 text-sm font-bold" >Enero:</label>
                                    <div className="">
                                        <input
                                            className='border-2 rounded-xl border-black p-1 pl-2'
                                            type="text"
                                            name="enero"
                                            value={datos.enero}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="my-2">
                                <div className='flex items-center gap-4 justify-between'>
                                    <label className="uppercase tracking-wide text-green-700 text-sm font-bold" htmlFor="grid-state">
                                        Febrero
                                    </label>
                                    <div className="">
                                        <input
                                            className='border-2 rounded-xl border-black p-1 pl-2'
                                            type="text"
                                            name="febrero"
                                            value={datos.febrero}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="my-2">
                                <div className='flex items-center gap-4 justify-between'>
                                    <label className="uppercase tracking-wide text-green-700 text-sm font-bold" htmlFor="grid-state">
                                        Marzo
                                    </label>
                                    <div className="">
                                        <input
                                            className='border-2 rounded-xl border-black p-1 pl-2'
                                            type="text"
                                            name="marzo"
                                            value={datos.marzo}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="my-2">
                                <div className='flex items-center gap-4 justify-between'>
                                    <label className="uppercase tracking-wide text-green-700 text-sm font-bold" htmlFor="grid-state">
                                        Abril
                                    </label>
                                    <div className="">
                                        <input
                                            className='border-2 rounded-xl border-black p-1 pl-2'
                                            type="text"
                                            name="abril"
                                            value={datos.abril}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="my-2">
                                <div className='flex items-center gap-4 justify-between'>
                                    <label className="uppercase tracking-wide text-green-700 text-sm font-bold" htmlFor="grid-state">
                                        Mayo
                                    </label>
                                    <div className="">
                                        <input
                                            className='border-2 rounded-xl border-black p-1 pl-2'
                                            type="text"
                                            name="mayo"
                                            value={datos.mayo}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="my-2">
                                <div className='flex items-center gap-4 justify-between'>
                                    <label className="uppercase tracking-wide text-green-700 text-sm font-bold" htmlFor="grid-state">
                                        Junio
                                    </label>
                                    <div className="">
                                        <input
                                            className='border-2 rounded-xl border-black p-1 pl-2'
                                            type="text"
                                            name="junio"
                                            value={datos.junio}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=''>
                            <div className="my-2">
                                <div className='flex items-center gap-4 justify-between'>
                                    <label className="uppercase tracking-wide text-green-700 text-sm font-bold" htmlFor="grid-state">
                                        Julio
                                    </label>
                                    <div className="">
                                        <input
                                            className='border-2 rounded-xl border-black p-1 pl-2'
                                            type="text"
                                            name="julio"
                                            value={datos.julio}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="my-2">
                                <div className='flex items-center gap-4 justify-between'>
                                    <label className="uppercase tracking-wide text-green-700 text-sm font-bold" htmlFor="grid-state">
                                        Agosto
                                    </label>
                                    <div className="">
                                        <input
                                            className='border-2 rounded-xl border-black p-1 pl-2'
                                            type="text"
                                            name="agosto"
                                            value={datos.agosto}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="my-2">
                                <div className='flex items-center gap-4 justify-between'>
                                    <label className="uppercase tracking-wide text-green-700 text-sm font-bold" htmlFor="grid-state">
                                        Setiembre
                                    </label>
                                    <div className="">
                                        <input
                                            className='border-2 rounded-xl border-black p-1 pl-2'
                                            type="text"
                                            name="setiembre"
                                            value={datos.setiembre}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="my-2">
                                <div className='flex items-center gap-4 justify-between'>
                                    <label className="uppercase tracking-wide text-green-700 text-sm font-bold" htmlFor="grid-state">
                                        Octubre
                                    </label>
                                    <div className="">
                                        <input
                                            className='border-2 rounded-xl border-black p-1 pl-2'
                                            type="text"
                                            name="octubre"
                                            value={datos.octubre}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="my-2">
                                <div className='flex items-center gap-4 justify-between'>
                                    <label className="uppercase tracking-wide text-green-700 text-sm font-bold" htmlFor="grid-state">
                                        Noviembre
                                    </label>
                                    <div className="">
                                        <input
                                            className='border-2 rounded-xl border-black p-1 pl-2'
                                            type="text"
                                            name="noviembre"
                                            value={datos.noviembre}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="my-2">
                                <div className='flex items-center gap-4 justify-between'>
                                    <label className="uppercase tracking-wide text-green-700 text-sm font-bold" htmlFor="grid-state">
                                        Diciembre
                                    </label>
                                    <div className="">
                                        <input
                                            className='border-2 rounded-xl border-black p-1 pl-2'
                                            type="text"
                                            name="diciembre"
                                            value={datos.diciembre}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </form>
            <button type="button"
                className="bg-green-700 hover:bg-green-500 text-white font-bold py-2 px-4 rounded-xl"
                onClick={guardarDatos}>Guardar</button>
            {
                mostrarBotonVerTablas && (
                    <div>
                        <p className="text-green-600 font-semibold mt-4">La información se ha guardado correctamente</p>
                        {mostrarBotonVerTablas && (
                            <button
                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-xl mt-2"
                                onClick={() => navigate("/tablas")}
                            >
                                Ver Tablas
                            </button>
                        )}
                    </div>
                )
            }
        </Layout >
    );
};

export default Edit;

