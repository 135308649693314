import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useLogin } from "../context/LoginContext";
import { supabase } from "../supabase/supabase";

const NewNavbar = () => {
  const [userData, setUserData] = useState(null);
  const { getSessionAuth } = useLogin();
  const [openSearch, setOpenSearch] = useState(false);
  const [menuResp, setMenuResp] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();


  useEffect(() => {
    const sessionAuth = async () => {
      const session = await getSessionAuth();
      if (session) {
        await getUser(session.id);
      }
    };
    sessionAuth();
  }, []);

  const getUser = async (id) => {
    const { error, data } = await supabase
      .from("users")
      .select()
      .eq(`id`, id)
      .single();
    if (error) {
      throw error.message
    };
    setUserData(data);
  };

  const openSearchIcon = () => {
    setOpenSearch(true);
  };

  const closeSearchIcon = () => {
    setOpenSearch(false);
  };

  const menuResponsive = () => {
    setMenuResp(!menuResp);
  };

  const handleSignOut = async () => {
    try {
      await supabase.auth.signOut();
      window.location.reload();
    } catch (error) {
      console.error("Error al cerrar sesión:", error.message);
    }
  };

  const closeMenu = () => {
    setMenuResp(!menuResp);
  };

  const handleSearch = async () => {
    console.log("Realizar búsqueda con:", searchTerm);
    navigate(`/busqueda/${searchTerm}`);
  };

  return (
    <nav className="w-full bg-[#B2EAB8] py-2">
      <div className="flex items-center justify-between w-full mx-auto p-4">
        <Link
          to="/home"
          className="flex items-center space-x-3 rtl:space-x-reverse"
        >
          <img src="/logo2.webp" className="w-[180px]" alt="Logo" />
        </Link>
        <div className="flex md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">

          <button
            className="bg-[#ffff] px-4 py-2 text-xs text-center text-black rounded-lg hover:bg-[#058237] hover:text-white transition-colors duration-200 mt-2 font-medium"
            onClick={menuResponsive}
          //  onClick={toggleMenu}
          >
            Menú
          </button>
        </div>
        {menuResp && (
          <div className="absolute top-0 right-0 h-[1010px] bg-white p-[30px] pt-[70px] z-3 fadeIn w-60">
            <div className="flex md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">
              {/* <input
                type="text"
                name="search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    handleSearch();
                    setMenuResp(!menuResp);
                  }
                }}
                className="block w-full placeholder:text-gray-400 sm:text-sm bg-transparent ring-1 ring-inset ring-gray-300 p-2 mr-4"
                placeholder="Buscar..."
              /> */}
            </div>

            <div className="close-menu">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="#356674"
                viewBox="0 0 24 24"
                strokeWidth={2.5}
                stroke="#356674"
                className="w-6 h-6 absolute right-6 top-6"
                onClick={closeMenu}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18 18 6M6 6l12 12"
                />
              </svg>
            </div>

            <div className="opt-menu-lateral">
              <div className="mt-4">
                <>
                  {/* <Link to="/perfil" className="flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="black"
                      className="w-6 h-6 mt-2 mr-2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                      />
                    </svg>

                    <h3 className="mt-2 flex text-sm" color="#000000">
                      Perfil
                    </h3>
                  </Link> */}

                  <Link to="/area" className="flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="black"
                      className="w-6 h-6 mt-2 mr-2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6.429 9.75 2.25 12l4.179 2.25m0-4.5 5.571 3 5.571-3m-11.142 0L2.25 7.5 12 2.25l9.75 5.25-4.179 2.25m0 0L21.75 12l-4.179 2.25m0 0 4.179 2.25L12 21.75 2.25 16.5l4.179-2.25m11.142 0-5.571 3-5.571-3"
                      />
                    </svg>
                    <h3 className="mt-2 flex text-sm" color="#000000">
                      Formularios
                    </h3>
                  </Link>

                  {/* {userData.role === "admin" && (
                    <>

                      <Link to="/usuarios" className="flex">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="black"
                          className="w-6 h-6 mt-2 mr-2"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
                          />
                        </svg>
                        <h3 className="mt-2 flex text-sm" color="#000000">
                          Usuarios
                        </h3>
                      </Link>
                    </>
                  )} */}
                  <button
                    type="button"
                    onClick={handleSignOut}
                    className="flex"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="black"
                      className="w-6 h-6 mt-2 mr-2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                      />
                    </svg>
                    <h3 className="mt-2 flex text-sm" color="#000000">
                      Salir
                    </h3>
                  </button>
                </>
              </div>
            </div>
          </div>
        )}
        <div
          className="items-center justify-between hidden w-full md:flex md:w-auto md:order-1"
          id="navbar-cta"
        >
          <ul className="flex flex-col font-medium p-4 md:p-0 mt-4 md:space-x-8 rtl:space-x-reverse md:flex-row ">
            <li>
              <Link to="/home">
                <button className="bg-[#ffff] text-xs text-center text-black rounded-lg hover:bg-[#058237] hover:text-white transition-colors duration-200 px-4 py-2">
                  Inicio
                </button>
              </Link>
            </li>
            <li>
              <Link to="/formulario">
                <button className="bg-[#ffff] text-xs text-center text-black rounded-lg hover:bg-[#058237] hover:text-white transition-colors duration-200 px-4 py-2">
                  Formularios
                </button>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NewNavbar;
