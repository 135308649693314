import { Route, Routes } from "react-router-dom";
import Form from "../pages/Form"
import LoginForm from "../pages/LoginForm";
import HomePage from "../pages/Home";
import Tablas from "../pages/Tablas";
import Edit from "../pages/Edit";
import Anualizados from "../pages/Anualizados";
import Trimestrales from "../pages/Trimestrales";


const Views = () => {
    return (
        <Routes>
            <Route path="/" index element={<LoginForm />} />
            <Route path="/home" element={<HomePage />} />
            <Route path="/formulario" element={<Form />} />
            <Route path="/anualizados" element={<Anualizados />} />
            <Route path="/trimestrales" element={<Trimestrales />} />
            <Route path="/tablas" element={<Tablas />} />
            <Route path="/editar/:id" element={<Edit />} />

        </Routes>
    );
};

export default Views;