import './App.css';
import { LoginContextProvider } from './context/LoginContext';
import { UserContextProvider } from './context/UserContext';
import Views from './router/Views';

function App() {
  return (
    <div className="App">
      <LoginContextProvider>
        <UserContextProvider>
          <Views />
        </UserContextProvider>
      </LoginContextProvider>
    </div>
  );
}

export default App;
