import { useEffect, useState } from "react";
import { supabase } from "../supabase/supabase";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useLogin } from "../context/LoginContext";
import Loading from "../components/Loading";
import Layout from "../layout/Layout";

const HomePage = () => {
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState(null);
  const { getSessionAuth } = useLogin();
  const navigate = useNavigate();

  useEffect(() => {
    const sessionAuth = async () => {
      const session = await getSessionAuth();
      if (session) {
        await getUser(session.id);
        setLoading(false);
      } else {
        console.log("error");
        navigate("/", { replace: true });
      }
    };
    sessionAuth();
  }, []);

  const getUser = async (id) => {
    const { error, data } = await supabase
      .from("users")
      .select("*")
      .eq(`id`, id)
      .single();
    if (error) {
      throw error.message;
    };
    setUserData(data);
  };

  return (
    <Layout>
      {loading && <Loading />}
      <div className="">
        <h1 className="text-[#058237] font-bold text-3xl">BIENVENIDO/A:</h1>
        <h1 className="text-black font-bold text-3xl">
          {userData && userData.name}
        </h1>
        <p className="mt-5 text-lg">
          Bienvenid@ al ingreso de información de Indicadores Económicos
        </p>
      </div>
      <div className="grid grid-cols-4 gap-5 mt-10">
        {/* <Link
          to="/perfil"
          className="bg-[#058237] uppercase text-white text-lg text-center py-5 rounded-lg hover:text-white hover:bg-[#36915a] transition-colors duration-300"
        >
          perfil
        </Link> */}
        <Link
          to="/formulario"
          className="bg-[#058237] uppercase text-white text-lg text-center py-5 rounded-lg hover:text-white hover:bg-[#36915a] transition-colors duration-300"
        >
          formularios
        </Link>
        <Link
          to="/tablas"
          className="bg-[#058237] uppercase text-white text-lg text-center py-5 rounded-lg hover:text-white hover:bg-[#36915a] transition-colors duration-300"
        >
          tablas
        </Link>
        <Link
          to="/anualizados"
          className="bg-[#058237] uppercase text-white text-lg text-center py-5 rounded-lg hover:text-white hover:bg-[#36915a] transition-colors duration-300"
        >
          formulario de anualizados
        </Link>
        <Link
          to="/trimestrales"
          className="bg-[#058237] uppercase text-white text-lg text-center py-5 rounded-lg hover:text-white hover:bg-[#36915a] transition-colors duration-300"
        >
          formulario de trimestrales
        </Link>
      </div>
    </Layout>
  );
};
export default HomePage;
