import { createContext, useContext, useState } from "react"
import { supabase } from "../supabase/supabase";

export const UserContext = createContext();

export const useUsers = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUsers debe estar dentro del proveedor UserContext");
  }
  return context;
};

export const UserContextProvider = ({ children }) => {
  const [users, setUsers] = useState([])
  // const [userData, setUserData] = useState([]);
  const [roles, setRoles] = useState([])

  const getUsers = async () => {
    const { error, data } = await supabase.from("users").select();
    if (error) throw error.message;
    setUsers(data);
  };

  const getUser = async (id) => {
    const { error, data } = await supabase
    .from("users")
    .select()
    .eq(`id`, id)
    .single();
    if (error) throw error.message;
    return (data);
  };

  const getRoles = async () => {
    const { error, data } = await supabase.from("rol").select();
    if (error) throw error.message;
    setRoles(data);
  };

  return (
    <UserContext.Provider
      value={{
        users,
        // userData,
        getUsers,
        getUser,
        roles,
        getRoles
      }}
    >
      {children}
    </UserContext.Provider>
  )
}